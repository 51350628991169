<template>
  <div class="Details">
    <h1 class="Details-title">{{ elem.name }}</h1>
    <div class="Details-description">
      <div class="Details-description-img">
        <img
          v-if="elem.image"
          :src="`https://api.verso2puntocero.com/image/l/${elem.image}`"
          :alt="elem.name"
        />
        <img v-else src="../assets/default.svg" alt="VERSO" />
      </div>
      <div class="Details-description-content">
        <h2 class="description-title">{{ $t(`details.${type}-biography`) }}</h2>
        <p class="description-text">
          {{ elem[`biography_${$i18n.locale}`] }}
        </p>
      </div>
    </div>

    <div class="Details-gallery">
      <h2 class="Details-gallery-title">{{ $t(`details.${type}-poems`) }}</h2>
      <div class="Gallery">
        <GalleryImage
          v-for="(poem, key) in elem.poems"
          :key="key"
          :imgUrl="imgUrlM + poem.image"
          :imgAlt="poem.title"
          :title="poem.title"
          :id="poem.id"
          :description="poem.desc"
          :type="type"
          :poet="poem.poet"
          :actor="poem.actor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GalleryImage from '@/components/details/GalleryImage'
import api from '@/services/api.service.js'
export default {
  name: 'Details',
  components: { GalleryImage },
  data() {
    return {
      details: [1, 2, 3, 4, 5, 6],
      elem: '',
      poems: [],
      imgUrl: process.env.VUE_APP_IMG_URL,
      imgUrlS: process.env.VUE_APP_SMALL_IMG_URL,
      imgUrlM: process.env.VUE_APP_MEDIUM_IMG_URL,
      imgUrlL: process.env.VUE_APP_LARGE_IMG_URL
    }
  },
  async created() {
    this.getDetailInfo()
  },
  watch: {
    $route() {
      this.getDetailInfo()
    }
  },
  methods: {
    async getDetailInfo() {
      this.type = this.$route.params.detailType
      this.typeId = this.$route.params.typeId
      if (this.type === 'actor') {
        const res = await api.get.actor(this.typeId)
        const actor = res.data.actor
        for await (let poem of actor.poems) {
          const res2 = await api.get.poet(poem.poet)
          poem.desc = res2.data.poet.name
        }
        this.elem = actor
      } else if (this.type === 'poet') {
        const res = await api.get.poet(this.typeId)
        const poet = res.data.poet
        for await (let poem of poet.poems) {
          const res2 = await api.get.actor(poem.actor)
          poem.desc = res2.data.actor.name
        }
        this.elem = poet
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Details {
  &-title {
    text-transform: uppercase;
  }
  &-description {
    margin-right: 70px;
    display: grid;
    grid-template-columns: 300px auto;
    .description-title {
      margin: 0;
    }
    &-img {
      margin-right: 20px;
      img {
        height: 300px;
        width: 300px;
        object-fit: cover;
      }
    }
  }
  &-gallery {
    &-title {
      margin-bottom: 20px;
    }
    margin: 30px auto;
    .Gallery {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 960px) {
    .Gallery {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
    }
  }
  @media only screen and (max-width: 540px) {
    .Details-description {
      display: flex;
      flex-direction: column;
      &-img {
        margin: 0 auto;
      }
    }
    .Gallery {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
}
</style>
